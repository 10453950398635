@font-face {
  font-family: GilroyAlt;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/GilroyAlt-Medium.eot?#iefix") format("embedded-opentype"),
    url(./fonts/GilroyAlt-Medium.woff2) format("woff2"),
    url(./fonts/GilroyAlt-Medium.woff) format("woff");
}

@font-face {
  font-family: GilroyAlt;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./fonts/GilroyAlt-SemiBold.eot?#iefix") format("embedded-opentype"),
    url(./fonts/GilroyAlt-SemiBold.woff2) format("woff2"),
    url(./fonts/GilroyAlt-SemiBold.woff) format("woff");
}

@font-face {
  font-family: GilroyAlt;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/GilroyAlt-Bold.eot?#iefix") format("embedded-opentype"),
    url(./fonts/GilroyAlt-Bold.woff2) format("woff2"),
    url(./fonts/GilroyAlt-Bold.woff) format("woff");
}
